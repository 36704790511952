import { Box, Container } from "@mui/material";
import React from "react";

const Terms = () => {
  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <div className="landing-bg_biggpay_font">Terms &amp; Conditions</div>
        <Box
          style={{
            width: "60px",
            height: "10px",
            backgroundColor: "#dc5f5f",
          }}
        ></Box>
        <div>
          <div>
            <div>
              <p className="landing-bg_para">
                We would like you to read these general terms of conditions
                <strong>(“Terms of Use” / “Terms of Service” / “Terms”)</strong>
                carefully&nbsp;before you use our platform or any of its
                services
                <strong>(“Services”)</strong>. These Terms are legally binding
                and govern the use of our website, our mobile application, our
                API and other properties collectively called as the
                <strong>“LINKSTECH SERVICES PRIVATE LIMITED”</strong> or
                <strong> “Platform”</strong>
                created by LINKSTECH SERVICES PRIVATE LIMITED s Pvt. Ltd., a
                company incorporated under the laws of India, with its
                registered office at Plot No. 8 Pocket, 6, Sector 22, Rohini,
                Delhi, 110086
                <strong>(“LINKSTECH ” or “Company”)</strong>.
              </p>

              <p className="landing-bg_para">
                These Terms are a binding agreement between you and LINKSTECH .
                These Terms are in addition to and not in derogation of any
                other terms stipulated by LINKSTECH SERVICES from time to time.
                In case of any conflict between these Terms and such other
                terms, these Terms will prevail. Throughout this document, we
                use the terms <strong>“we”</strong> and
                <strong>“our”</strong> to refer to LINKSTECH SERVICES and the
                terms
                <strong>“you”</strong> and <strong>“your”</strong> to refer to
                you, the User of the Platform. Both LINKSTECH SERVICES and the
                User are collectively referred to as <strong>“Parties”</strong>{" "}
                and singularly referred to as
                <strong>“Party”</strong>.
              </p>
              <p className="landing-bg_para">
                We may revise these Terms, update the Platform and modify the
                Services at any time to improve your experience on our Platform
                and on account of changes in Applicable Law.&nbsp; If you do not
                agree with any change made by us, you have the option to stop
                using our Services. These Terms refer to and include our{" "}
                <strong>Privacy Policy.</strong>
              </p>

              <div className="landing-bg_para">
                <h3 className="h5">
                  <abbr>ACCEPTANCE</abbr>
                </h3>
                <p className="landing-bg_para">
                  These T&amp;C were last updated on 8th July, 2022. Please
                  carefully read these T&amp;C. By signing-up to use the
                  Platform, you acknowledge that you have read these Terms and
                  agree to be bound by them. You also acknowledge that you have
                  read our Privacy Policy and agree to be bound by it. By
                  signing up to use the Platform, you represent that you have
                  provided us with valid credentials&nbsp;which may be used to
                  verify your identity. Please note that we may restrict,
                  suspend, or terminate your access at any time to the Platform
                  if we believe you have breached these Terms. For the security
                  of your account and to enhance your overall experience on the
                  Platform, we may use certain technologies to monitor your
                  activities on the Platform.
                </p>
              </div>

              <div className="landing-bg_para">
                <h3>
                  <abbr>ELIGIBILITY</abbr>
                </h3>
                <p className="landing-bg_para">
                  LINKSTECH ’s services are available to anyone having a valid
                  email address and an Indian mobile phone number.
                  The&nbsp;Platform must be accessed/used only by the persons
                  who are eligible to enter into legally binding contracts under
                  the Indian Contract Act and are ‘competent to contract’
                  according to Section 11 of the Indian Contract Act. Users who
                  have been previously suspended or removed from availing any
                  service by LINKSTECH SERVICES shall not be eligible to avail
                  services from LINKSTECH . Users shall not impersonate any
                  person or entity, or falsely state or otherwise misrepresent
                  identity, age or affiliation with any person or entity.
                </p>
              </div>

              <div className="landing-bg_para">
                <h3 className="h5">
                  <abbr>DEFINITIONS</abbr>
                </h3>

                <p className="landing-bg_para">
                  For the purpose of these Terms of Use including General Terms
                  and Conditions, Digi Kendra Terms and Conditions and Prepaid
                  Card Terms and Conditions, the following words and phrases
                  shall have the meaning assigned to them under this Article.
                </p>

                <p className="landing-bg_para">
                  <strong>“Applicable Law”</strong>
                  includes all applicable Indian statutes, enactments, acts of
                  the state legislature or parliament, laws, ordinances, rules,
                  bye-laws, regulations, notifications, guidelines, directions,
                  directives and orders of any governmental authority, statutory
                  authority, board, recognized stock exchange, as may be
                  applicable.
                </p>

                <p className="landing-bg_para">
                  <strong>“Chargeback”</strong>
                  shall mean and include reversal of any transaction made by
                  Service Provider’s User inter alia, on account of, including
                  but not limited to:
                </p>
                <div className="landing-bg_para">
                  <div className="landing-bg_para">
                    any alleged forgery of the User’s account, card or other
                    details pertaining to the transaction;
                  </div>
                  <div className="landing-bg_para">
                    any charge/debit made on account or card that has been
                    listed as a hot listed card or otherwise listed on the card
                    association warning bulletins;
                  </div>
                  <div className="landing-bg_para">
                    duplicate processing of the transaction;
                  </div>
                  <div className="landing-bg_para">
                    any amount required to be refunded due to, denial of a
                    transaction by the User / valid card holder as wrongly
                    charged payment / extra payments and/or due to the
                    fraudulent use / misuse of the personal and financial
                    information of the Service Provider’s User by any
                    unauthorized person and / or any other reason as required /
                    approved by the concerned banks, as the case may be
                  </div>
                </div>

                <p className="landing-bg_para">
                  <strong>“Confidential Information”</strong> means and includes
                  the Intellectual Property and any and all business, technical
                  and financial information of LINKSTECH SERVICES or of any of
                  its affiliates that is related to any of the arrangements
                  contemplated in these Terms of Use or any other document in
                  which these Terms of Use are incorporated by reference or
                  otherwise disclosed by LINKSTECH SERVICES to the User any
                  information which relates to its financial and/or business
                  operations, including but not limited to, specifications,
                  models, merchant lists/information samples, reports,
                  forecasts, current or historical data, computer programs or
                  documentation and all other technical, financial or business
                  data, information related to its internal management,
                  customers, products, services, anticipated products/services,
                  processes, financial condition, employees, merchants,
                  Intellectual Property, marketing strategies, experimental
                  work, trade secrets, business plans, business proposals,
                  customer contract terms and conditions,
                  compensation/commission/ service charges payable to the User,
                  and other valuable confidential information and materials that
                  are customarily treated as confidential or proprietary,
                  whether or not specifically identified as confidential or
                  proprietary.
                </p>

                <p className="landing-bg_para">
                  <strong>“Effective Date”</strong> or
                  <strong>“Registration Date”</strong>
                  shall mean the date of registration of account of the User on
                  LINKSTECH ’s platform.
                </p>

                <p className="landing-bg_para">
                  <strong>“LINKSTECH SERVICES PRIVATE LIMITED Policies”</strong>{" "}
                  shall mean and include the Terms of Use, Privacy Policy,
                  Grievance Policy and any other such terms and
                  conditions/policies available and updated from time to time on
                  the LINKSTECH SERVICES PRIVATE LIMITED.
                </p>

                <p className="landing-bg_para">
                  <strong>“Intellectual Property”</strong> shall mean all
                  intellectual property used for the purpose of or in
                  association with or in relation to the performance of these
                  Terms of Use and Applicable Agreement, and includes without
                  limitation, (a) Software, operating manuals, software code,
                  program, instructions, specifications, processes, input
                  methods, data or information used in relation to, or in
                  association with, or for the operation of the software
                  installed by the Company, (b) the trademarks, service marks,
                  trade names, business names, logos, symbols, styles, colour
                  combinations used by the Company during the course of its
                  business and all depictions, derivations and representations
                  graphics, images, content and the ‘look and feel’ of all of
                  the above, (d) all information, data or material in whatever
                  form, whether tangible or not, provided by the Company to the
                  User during the course of Applicable Agreement; and (e) all
                  techniques, formulae, patterns, compilations, processes,
                  inventions, practices, methodology, techniques, improvement,
                  utility model, procedures, designs, skills, technical
                  information, notes, experimental results, service techniques,
                  samples, specifications of the products or services, labelling
                  specifications, rights on software, and any other knowledge or
                  know-how of any nature whatsoever.
                </p>

                <p className="landing-bg_para">
                  <strong>“KYC”</strong> or{" "}
                  <strong>“Know Your Customer”</strong> shall mean the process
                  prescribed under the Applicable Law to collect and
                  authenticate the User’s personal or business identification
                  details before offering the Services to the User.
                </p>

                <p className="landing-bg_para">
                  <strong>“Services”</strong> shall mean the services provided
                  by LINKSTECH SERVICES and various Service Providers to the
                  Users utilizing the LINKSTECH SERVICES PRIVATE LIMITED
                  including but not limited to banking services, non-banking
                  services and other such services either by itself or through a
                  Merchant.
                </p>

                <p className="landing-bg_para">
                  <strong>“Service Provider”</strong> shall mean any banking
                  and/or non-banking entity with which LINKSTECH SERVICES has,
                  directly or indirectly, executed an agreement for facilitating
                  Transactions between such entity and its customers.
                </p>

                <p className="landing-bg_para">
                  <strong>“Users”</strong> shall mean individuals&nbsp;including
                  all body&nbsp;corporates, partnerships, limited liability
                  partnerships, etc., who are availing LINKSTECH SERVICES
                  services through the LINKSTECH SERVICES PRIVATE LIMITED.
                </p>
              </div>

              <div className="landing-bg_para">
                <h3 className="h5">1. GENERAL TERMS AND CONDITIONS</h3>
                <p>
                  These General Terms and Conditions govern the User’s access to
                  the Services provided by LINKSTECH :
                </p>
              </div>

              <div className="landing-bg_para">
                <div>
                  <u>INFORMATION SUPPLIED TO INSTANTPAY.</u> Users agree that
                  the information provided by the Users to LINKSTECH SERVICES on
                  registration and at all other times, including payment, is
                  true, accurate, current, and complete.
                </div>
                <div className="landing-bg_para">
                  <u>SECURITY OF ACCOUNT.</u> Users’ mobile number and email
                  identifies a Users’ account with LINKSTECH SERVICES through
                  which LINKSTECH SERVICES services are dispensed (“
                  <strong>Account</strong>
                  ”). LINKSTECH SERVICES encourages Users to choose a strong
                  password to protect their Account. It is advised that Users do
                  not share their password with anyone or write it down. Neither
                  LINKSTECH nor its Service Providers, under any circumstances,
                  will ever ask for a User's Account password or any other
                  confidential information. If a User has any reason to believe
                  that his Account is no longer secure, then the User shall
                  immediately change the Account password and indemnify
                  LINKSTECH SERVICES from any liabilities that may arise from
                  the misuse of such User’s Account.
                </div>

                <div className="landing-bg_para">
                  <u>PRIVACY POLICY.</u> All information collected from a User
                  pursuant to provision of LINKSTECH ’s services, and
                  information collected by Merchants or Digi Kendras during the
                  performance of these Terms of Use or Applicable Agreement, is
                  subject to LINKSTECH ’s
                  <a href="/privacy-policy">Privacy Policy</a>.
                </div>

                <div className="landing-bg_para">
                  <u>RECLAIMING INACTIVE ACCOUNTS.</u>. If an Account is found
                  to be overly inactive, the Account may be reclaimed by
                  LINKSTECH SERVICES without providing notice to the concerned
                  User. An Account is considered as inactive if the concerned
                  User is not signing in and/or has not done any transactions
                  for more than 6 consecutive months.
                </div>

                <div className="landing-bg_para">
                  <u>THIRD PARTY SITES, PRODUCTS AND SERVICES.</u> LINKSTECH ’s
                  services and/or the Site may include links or references to
                  other web sites or services solely for the Users’ convenience
                  (“<strong>Reference Sites</strong>”). LINKSTECH SERVICES does
                  not endorse any such Reference Sites or the information,
                  materials, products, or services contained on or accessible
                  through Reference Sites. Correspondence or business dealings
                  between Users and concerned Reference Sites are solely between
                  such User and the concerned Reference Site. Access and use of
                  Reference Sites, including the information, materials,
                  products, and services on or available through Reference Sites
                  is solely at the concerned User’s own risk.
                </div>

                <div className="landing-bg_para">
                  <u>COMMUNICATION POLICY.</u> The User hereby accepts that:
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      LINKSTECH SERVICES may send transactional or
                      non-transactional business communication in the form of
                      SMS or Email or Push notifications as part of its efforts
                      to fulfil the User’s request or service your account. A
                      User may continue to receive transactional and
                      critical&nbsp;communication from LINKSTECH SERVICES even
                      after opting out of marketing communications.
                    </div>
                    <div className="landing-bg_para">
                      LINKSTECH SERVICES will make the best efforts to provide
                      notifications and it shall be deemed that the User shall
                      have received the information sent from the Company during
                      the course of, or in relation to, using the LINKSTECH
                      Platform or availing any Services. LINKSTECH SERVICES
                      shall not be under any obligation to confirm the
                      authenticity of the person(s) receiving the alert. The
                      User cannot hold the Company liable for non-availability
                      of any notification service in any manner whatsoever.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>LIMITATION OF LIABILITY.</u>
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      In no event will LINKSTECH SERVICES or its directors,
                      agents, officers, or employees be liable to a User for any
                      special, indirect, incidental, consequential, punitive, or
                      exemplary damages (including without limitation lost
                      business opportunities, lost revenues, or loss of
                      anticipated profits or any other pecuniary or
                      non-pecuniary loss or damage of any nature whatsoever) of
                      any kind arising out of or relating to.
                      <p className="mt-2">
                        a) &nbsp; this Terms of Use or any other agreement
                        entered by a User with LINKSTECH ,
                      </p>
                      <p>
                        b) &nbsp; LINKSTECH ’s services, the Site or any
                        Reference site, or
                      </p>
                      <p>
                        c)&nbsp; User’s use or inability to use LINKSTECH ’s
                        services, the Site or any Reference Sites, even if
                        LINKSTECH SERVICES or a LINKSTECH SERVICES authorized
                        representative has advised of the possibility of such
                        damages,
                      </p>
                      <p>
                        d)&nbsp; unauthorized access to or alterations of
                        transmissions or data, any material or data sent or
                        received or not sent or received,
                      </p>
                      <p>
                        e)&nbsp; any transactions entered into by any third
                        person or conduct of any other third party or any
                        infringement of another’s rights,
                      </p>
                      <p>
                        f)&nbsp; the use of counterfeit or stolen cards, or
                        stolen devices, or
                      </p>
                      <p>g)&nbsp; fraudulent electronic transactions.</p>
                    </div>

                    <div className="landing-bg_para">
                      It shall be at the sole discretion of LINKSTECH SERVICES
                      to reverse any transaction subject to approval of the
                      concerned Service Provider. Notwithstanding the above, if
                      any court of law finds that LINKSTECH SERVICES or its
                      directors, officers, or employees, are liable to indemnify
                      a concerned User despite the existence of this Clause 1.7,
                      such liability shall not exceed the amount paid by the
                      concerned User, if any, for using the portion of the
                      LINKSTECH ’s services or the Site giving rise to the cause
                      of action.
                    </div>

                    <div className="landing-bg_para">
                      Users acknowledge and agree that LINKSTECH SERVICES has
                      offered its products and services, set its prices, and
                      entered into these Terms of Use and Applicable Agreement
                      in reliance upon the warranty, disclaimers, and the
                      limitations of liability set forth herein.
                    </div>

                    <div className="landing-bg_para">
                      Users acknowledge that the warranty, disclaimers, and the
                      limitations of liability set forth herein reflect a
                      reasonable and fair allocation of risk between Users and
                      LINKSTECH , and that the warranty, disclaimers, and the
                      limitations of liability set forth herein form an
                      essential basis of the bargain between Users and LINKSTECH
                      . LINKSTECH SERVICES would not be able to provide the
                      services to Users on an economically reasonable basis
                      without these limitations.
                    </div>
                    <div className="landing-bg_para">
                      Users understand that LINKSTECH SERVICES or the Service
                      Providers may reject authentication and/or authorization
                      of transaction placed by Users for any reason including
                      but not limited to insufficient funds, incorrect
                      authentication details provided, expired card/bank
                      account, risk management, suspicion of fraudulent
                      transactions, selling of restricted or banned items, use
                      of compromised cards or bank account numbers, use of
                      banned/blacklisted cards or bank account numbers, use of
                      suspicious API or in accordance with the regulator,
                      Issuing, Acquiring Institution and/or Card
                      Network&nbsp;rules, guidelines, regulations, etc. and any
                      other laws, rules, regulations, guidelines in force in
                      India.
                    </div>
                    <div className="landing-bg_para">
                      User further acknowledge that as a security measure
                      LINKSTECH SERVICES and/or the Service Providers may at our
                      sole discretion, permanently or temporarily, block any
                      card number, account numbers, group of cards or
                      transactions from any specific blocked or blacklisted
                      cards / accounts, specific, group of IP addresses,
                      devices, geographic locations and / or any such risk
                      mitigation measures it wishes to undertake.
                    </div>
                    <div className="landing-bg_para">
                      As a risk management tool, LINKSTECH SERVICES and/or the
                      Service Providers reserve the right to limit or restrict
                      transaction size, amount and/or monthly volume at any
                      time. LINKSTECH SERVICES will consider a variety of
                      factors in making a decision and such determination will
                      be at our sole discretion.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>FRAUDULENT TRANSACTIONS</u>
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      If LINKSTECH SERVICES is intimated, by the bank, that a
                      customer has reported an unauthorized debit/chargeback of
                      the customer’s Payment Instrument (“Fraudulent
                      Transaction”), the User shall be notified by LINKSTECH .
                      The User shall be entitled to furnish documents and
                      information pertaining to the Transaction associated with
                      the Chargeback within one&nbsp;(1) day&nbsp;(or such other
                      period specified by the bank).
                    </div>
                    <div className="landing-bg_para">
                      The User agrees and acknowledges that
                      <p>
                        a)&nbsp; if the User is unable to furnish Chargeback
                        Documents
                      </p>
                      <p>
                        b)&nbsp; the bank is not satisfied with the Chargeback
                        documents furnished by the User, then the bank shall be
                        entitled to order the LINKSTECH SERVICES to affect a
                        reversal of the debit of the Chargeback Amount
                        associated with the Chargeback such that the said
                        chargeback amount is credited to the customer’s Payment
                        Instrument.
                      </p>
                    </div>
                    <div className="landing-bg_para">
                      LINKSTECH SERVICES shall also be entitled to suspend the
                      settlement of the amount under dispute or hold the value
                      of transaction under dispute from following the settlement
                      of the transaction during the pendency of inquiries,
                      investigations and resolution thereof by the Service
                      Providers to the User.
                    </div>

                    <div className="landing-bg_para">
                      Pursuant to clause 1.8 (ii) above, if the amount in
                      respect of the Fraudulent Transaction has already been
                      settled to the User, any dispute arising in relation to
                      the said Fraudulent Transaction, shall be resolved in
                      accordance with the notifications, circulars and
                      guidelines issued by the regulators or Service Providers
                      in this regard from time to time.
                    </div>

                    <div className="landing-bg_para">
                      The User also agrees and acknowledges that it is liable to
                      pay fines, penalties and charges imposed by the Banks,
                      card payment networks or any regulatory authority on
                      account of Transactions that are in violation of
                      Applicable Law.
                    </div>
                    <div className="landing-bg_para">
                      The User further agrees and acknowledges that following
                      LINKSTECH SERVICES incurring the charge stipulated in
                      clause 1.8(iii), if the available Transaction Amounts are
                      insufficient for deduction of the Chargeback Amount, then
                      LINKSTECH SERVICES is entitled to issue a debit note
                      seeking reimbursement of the Chargeback Amount from the
                      User. The User shall reimburse the Chargeback Amount
                      within three (3) working days of receipt of the debit
                      note.
                    </div>
                    <div className="landing-bg_para">
                      The Parties hereto agree that any payment instructions for
                      Transactions accepted by the User as per the terms hereof
                      which are subsequently disputed due to (a) the
                      authorization by the User of any Transaction with an
                      amount exceeding the User’s account balance or credit
                      limit, or (b) the authorization by the User of a
                      fraudulent Transaction, shall always be the liability and
                      responsibility of User.
                    </div>
                    <div className="landing-bg_para">
                      The User hereby acknowledges and agrees that LINKSTECH
                      SERVICES is a facilitator for payment processing services
                      which are requested by Users and LINKSTECH SERVICES shall
                      not be responsible for any unauthorized transaction done
                      by any person including third party and amounting to
                      infringement of another’s rights or any chargebacks
                      claimed by the customers. It will be the responsibility of
                      Users to ensure due protection while transacting online or
                      otherwise. LINKSTECH SERVICES will assist the Users in
                      settling any queries related to the Services that arise
                      between the Users, and its customers. However, LINKSTECH
                      SERVICES shall bear no responsibility with respect to the
                      dispute or query related to payment made by the customers
                      to the Users.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>SECURITY OF YOUR ACCOUNT.</u>
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      The User shall comply with the provisions contained in the
                      Information Technology Act, 2000 and the statutory rules
                      framed there under, from time to time, in so far as the
                      same has application to its operations in accordance with
                      these Terms of Use, and also with all other Laws, rules
                      and regulations, whether already in force or which may be
                      enacted from time to time, pertaining to data security and
                      management, data storage, sharing and data protection, and
                      various rules, regulation and provisions as may be
                      applicable, as and when the same is enacted into a law and
                      comes into force, and shall ensure the same level of
                      compliance by all its employees.
                    </div>
                    <div className="landing-bg_para">
                      The User confirms and certifies that it shall not engage
                      in any act that violate any laws and regulations. It shall
                      also ensure that the financial data or any other
                      information of the customers received by the User are
                      stored in a system only in India. This data shall include
                      full end to end transaction details/information
                      collected/carried/processed as part of the message/payment
                      instructions. The User also acknowledges and agrees that
                      the account balance maintained by the Users on LINKSTECH
                      Platform shall be non-interest bearing and shall be
                      forfeited by LINKSTECH SERVICES if found lying unused for
                      more than 365 days.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>USER’S REPRESENTATIONS AND WARRANTIES.</u>
                  The User represents and warrants that.
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      it has had a full and adequate opportunity to read and
                      review these Terms of Use and had sufficient time to
                      evaluate and investigate the provision of services under
                      the Applicable Agreement and the financial requirements
                      and risks associated with the same.
                    </div>
                    <div className="landing-bg_para">
                      it shall immediately intimate LINKSTECH SERVICES of any
                      violation or potential violation of these Terms of Use or
                      of other circumstances that may cause damage to the
                      goodwill and reputation of LINKSTECH ,
                    </div>
                    <div className="landing-bg_para">
                      it shall be responsible to obtain any and all consents and
                      approvals that are required from regulatory or
                      governmental authorities, including any consent from
                      customers required to transfer data to the User and/or the
                      LINKSTECH SERVICES PRIVATE LIMITED, as the case may be, in
                      accordance with Applicable Law,
                    </div>

                    <div className="landing-bg_para">
                      it shall be solely responsible for and hereby undertakes
                      to strictly comply with Privacy Policy, and Applicable Law
                      having jurisdiction in any manner whatsoever in connection
                      with discharge of its duties under the Applicable
                      Agreement, including but not limited to data protection,
                      security, piracy, and directions issued by the regulators
                      relating to payment data, and shall obtain and maintain in
                      full force and effect all registrations required under
                      Applicable Law for the operation of the business in terms
                      of the Applicable Agreement, and
                    </div>
                    <div className="landing-bg_para">
                      it shall not access (or attempt to access) the website and
                      the Products by any means other than through the interface
                      that is provided by LINKSTECH . It shall not use any
                      deep-link, page scrape, robot, spider or other automatic
                      device, program, algorithm or methodology, or any similar
                      or equivalent manual process, to access, acquire, copy or
                      monitor any portion of the LINKSTECH SERVICES PRIVATE
                      LIMITED, or in any way reproduce or circumvent the
                      navigational structure or presentation of the platform,
                      without LINKSTECH ’s express prior written consent.
                    </div>
                    <div className="landing-bg_para">
                      it shall not attempt to gain unauthorized access to any
                      portion or feature of the Platform, or any other systems
                      or networks connected to the Platform or to any server,
                      computer, network, or to any of the services offered on or
                      through the Platform, by hacking, password "mining" or any
                      other illegitimate means.
                    </div>
                    <div className="landing-bg_para">
                      it shall inform the Company in case Income Tax Return is
                      not filed by the User as per sec 139(1) of Income Tax Act,
                      1961 for the previous financial years starting from FY
                      2018-19 and undertake to indemnify and keep indemnified
                      LINKSTECH SERVICES against any and all losses, claims,
                      liabilities, costs, taxes, interest, penalties imposed by
                      any government and regulatory authority for non-complying
                      with Section 206AB or any other provisions of Income Tax
                      Act, 1961. The User also agrees that in case of any
                      non-compliance as per the above-mentioned section the
                      Company shall recover the TDS amount from the User along
                      with interest and penalty.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>INDEMNIFICATION.</u>
                  User agrees to indemnify, save, and hold LINKSTECH , its
                  affiliates, contractors, employees, officers, directors,
                  agents and its third-party suppliers, licensors, and partners
                  harmless from any and all claims, demands, actions, suits
                  which may be threatened or brought against LINKSTECH , and
                  also against all losses, damages, liabilities, costs, charges
                  and expenses, including without limitation, legal fees and
                  expenses arising out of or related to:
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      Users’ use or misuse of LINKSTECH ’s services or of the
                      Site/application
                    </div>
                    <div className="landing-bg_para">
                      any violation by the User of Terms of Use or Applicable
                      Agreement,
                    </div>
                    <div className="landing-bg_para">
                      any breach of representations, warranties and covenants
                      made by the User in these Terms of Use, or Applicable
                      Agreement,
                    </div>
                    <div className="landing-bg_para">
                      any claim or legal notice or quasi-legal proceedings to
                      which LINKSTECH SERVICES may be required to become party
                      or to which LINKSTECH SERVICES may be subjected by any
                      person including any governmental authority, by reason of
                      breach of any Applicable Law,
                    </div>
                    <div className="landing-bg_para">
                      due to failure of a User to obtain any required statutory
                      or regulatory approval necessary for the performance of
                      its obligations in the Applicable Agreement with LINKSTECH
                      ,
                    </div>
                    <div className="landing-bg_para">
                      all liability, claims, damages, costs, expenses suffered
                      or incurred by LINKSTECH SERVICES as a result of any act
                      or violation by User under Section 269ST of the Income Tax
                      Act, 1961 while acting as collection agent of the Company.
                    </div>
                    <div className="landing-bg_para">
                      LINKSTECH SERVICES reserves the right, at User’s expense,
                      to assume the exclusive defense and control of any matter,
                      including rights to settle, for which concerned User is
                      required to indemnify LINKSTECH . The User agrees to
                      cooperate with LINKSTECH ’s defense and settlement of
                      these claims. LINKSTECH SERVICES will use reasonable
                      efforts to notify the User of any claim, action, or
                      proceeding brought by a third party that is subject to the
                      foregoing indemnification upon becoming aware of it.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>DISCLAIMER</u>
                  To the fullest extent permissible pursuant to Applicable Law,
                  LINKSTECH SERVICES and its officers, directors, employees, and
                  agents disclaim all warranties, express or implied, including,
                  but not limited to, implied warranties of merchantability,
                  fitness for a particular purpose, and non-infringement of
                  proprietary rights. No advice or information, whether oral or
                  written, obtained by a User from LINKSTECH SERVICES or through
                  LINKSTECH ’s services or the Site will create any warranty not
                  expressly stated herein. LINKSTECH SERVICES does not authorize
                  anyone to make any warranty on its behalf and Users should not
                  rely on any such statement. Users acknowledge that LINKSTECH
                  SERVICES is a reseller and is not liable for any third-party
                  seller’s obligations. It is User’s responsibility to evaluate
                  the accuracy, completeness and usefulness of all opinions,
                  advice, merchandise and other information provided through the
                  Site or on the internet generally. LINKSTECH SERVICES does not
                  warrant that its services will be uninterrupted, or free of
                  errors, viruses or other harmful components, and that any of
                  the foregoing defects will be corrected. LINKSTECH ’s services
                  and the Site and any data, information, third party software,
                  Reference Sites, or Software made available in conjunction
                  with or through its services and the Site are provided on an
                  “as is” and “as available”, “with all faults” basis and
                  without warranties or representations of any kind either
                  express or implied. LINKSTECH SERVICES and its third-party
                  suppliers, licensors, and partners do not warrant or make any
                  representations regarding the use or the results of the use of
                  LINKSTECH ’s services, the Site or any Reference Sites in
                  terms of correctness, accuracy, reliability, or otherwise.
                </div>

                <div className="landing-bg_para">
                  <u>INTELLECTUAL PROPERTY.</u>
                  <div className="custom-list points-alpha mt-3">
                    <div className="landing-bg_para">
                      LINKSTECH ’s services and the Site are owned and operated
                      by LINKSTECH SERVICES and/or third-party licensors. The
                      visual interfaces, graphics, design, compilation,
                      information, computer code (including source code and
                      object code), products, software, services, and all other
                      elements of LINKSTECH ’s services and the Site provided by
                      LINKSTECH SERVICES (the <strong> “Materials”</strong>) are
                      protected by Applicable Law. As between User and LINKSTECH
                      , all Materials and LINKSTECH ’s Intellectual Property are
                      the property of LINKSTECH SERVICES and/or third-party
                      licensors or suppliers. User agrees not to remove,
                      obscure, or alter LINKSTECH SERVICES or any third party's
                      copyright, patent, trademark, or other proprietary rights
                      notices affixed to or contained within or accessed in
                      conjunction with or through LINKSTECH ’s services. Except
                      as expressly authorized by LINKSTECH , the User agrees not
                      to sell, license, distribute, copy, modify, publicly
                      perform or display, transmit, publish, edit, adapt, create
                      derivative works from, or otherwise make unauthorized use
                      of the Materials or LINKSTECH ’s Intellectual Property.
                    </div>
                    <div className="landing-bg_para">
                      Parties do not accrue any rights or interest in the other
                      Party’s Intellectual Property and use of any Intellectual
                      Property by either Party shall be strictly for the
                      fulfilment of and in compliance with the terms of the
                      agreement between the Parties.
                    </div>
                    <div className="landing-bg_para">
                      The Parties undertake that they shall not, nor will they
                      allow others to, reverse engineer or disassemble any parts
                      of the other’s Intellectual Property.
                    </div>
                    <div className="landing-bg_para">
                      If Users’ have comments regarding LINKSTECH 's services
                      and the Site or ideas on how to improve it, please write
                      to our 24x7 Helpdesk at help@instantpay.in. Please note
                      that by doing so, concerned User hereby irrevocably assign
                      to LINKSTECH , and shall assign to LINKSTECH , all right,
                      title and interest in and to all ideas and suggestions and
                      any and all worldwide intellectual property rights
                      associated therewith. The concerned User agrees to perform
                      such acts and execute such documents as may be reasonably
                      necessary to perfect the foregoing rights.
                    </div>
                    <div className="landing-bg_para">
                      The User undertakes that:
                      <div className="custom-list points-roman">
                        <div className="custom-list-item">
                          they shall use the Intellectual Property solely for
                          discharge of their duties under the Applicable
                          Agreement,
                        </div>
                        <div className="custom-list-item">
                          they shall use Intellectual Property of other party
                          only in the form and manner stipulated by such other
                          party,
                        </div>
                        <div className="custom-list-item">
                          they shall seek prior written consent from other party
                          for use of such party’s Intellectual Property which is
                          not previously provided for by such Party,
                        </div>

                        <div className="custom-list-item">
                          they shall bring to other party’s notice all cases
                          concerning such party’s Intellectual Property’s (a)
                          infringement, (b) passing off, (c) registration, or
                          (d) attempted registration,
                        </div>

                        <div className="custom-list-item">
                          they shall render to other party all assistance in
                          connection with any matter pertaining to the
                          protection of such party’s Intellectual Property
                          whether in courts, before administrative agencies, or
                          otherwise,
                        </div>

                        <div className="custom-list-item">
                          they shall refrain from taking any action which shall
                          or may impair other party’s right, title or interest
                          in the Intellectual Property, or create any right,
                          title or interest therein or thereto, adverse to that
                          of the other party,
                        </div>

                        <div className="custom-list-item">
                          they shall not use or permit to be used the
                          Intellectual Property by any unauthorized person, and
                        </div>

                        <div className="custom-list-item">
                          they shall not misuse the Intellectual Property or use
                          it together with any other mark or marks.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>MODIFICATION.</u>
                  LINKSTECH SERVICES reserves the right to change, modify, add,
                  or remove these Terms of Use, either in its entirety or a part
                  thereof (each, a<strong>“change”</strong>) at any time by
                  posting a notification to the Site or otherwise communicating
                  the notification to Users. The changes will become effective,
                  and shall be deemed accepted by Users, 24 hours after the
                  initial posting and shall apply immediately on a going-forward
                  basis with respect to payment transactions initiated after the
                  posting date. If Users do not agree with any such
                  modification, Users sole and exclusive remedy is to terminate
                  their use of the Services.
                </div>

                <div className="landing-bg_para">
                  <u>CONFIDENTIALITY.</u>
                  <div className="landing-bg_para">
                    <div className="landing-bg_para">
                      The User shall keep Confidential Information as
                      confidential. The User confirms that it shall protect
                      Confidential Information with such security,
                      confidentiality and degree of utmost care as it would
                      prudently apply to its own confidential information and
                      use it solely in connection with the transaction to which
                      the Confidential Information relates. The User
                      acknowledges and agrees that it is aware of the
                      sensitivity &amp; secrecy involved in keeping the customer
                      data/information and transaction records and shall ensure
                      that neither the User nor their employees, directors etc.
                      will do any act to violate the same.
                    </div>
                    <div className="landing-bg_para">
                      Notwithstanding anything contained in this Clause 1.15,
                      Confidential Information shall exclude any information; a)
                      which is already in the possession of the receiving Party
                      and not subject to any other duty of confidentiality, b)
                      that is at the date hereof, or subsequently becomes,
                      public otherwise than by reason of a breach by the
                      receiving Party of these Terms of Use, c) Information that
                      becomes legally available to the receiving Party and/or
                      its affiliates or professional advisors on a
                      non-confidential basis from any third party, the
                      disclosure of which does not, to the knowledge of that
                      Party, violate any contractual or legal obligation which
                      such third party has to the other Party with respect to
                      such information, and d) Information that is independently
                      acquired or developed by the receiving Party and/or its
                      affiliates or professional advisors.
                    </div>
                    <div className="landing-bg_para">
                      The User hereby agrees that it shall not disclose any
                      Confidential Information received by it without the prior
                      written consent of the Company to any third party at any
                      time. Provided however, that either Party may make the
                      following disclosures for which no consent shall be
                      required from the other Party:
                      <p>
                        (a) Disclosures to its directors, officers, employees,
                        affiliates/subsidiaries/group/holding companies, third
                        party service providers and any employees thereof that
                        it reasonable determines need to receive the
                        Confidential Information;
                      </p>
                      <p>
                        (b) Disclosures to its legal and other professional
                        advisers, instructed by it that it reasonably determines
                        need to receive the Confidential Information; or
                      </p>
                      <p>
                        (b) Disclosures to any person to whom it is required by
                        law or any applicable regulatory, supervisory, judicial
                        or governmental order, to disclose such information, or
                        at the request of any regulatory or supervisory or
                        judicial or government authority.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>TERMINATION</u>
                  <div className="landing-bg_para">
                    LINKSTECH SERVICES reserves the right to suspend or
                    terminate the User’s access to LINKSTECH SERVICES PRIVATE
                    LIMITED and Services without prior notice and without any
                    liability to the User or any third party, in the event,
                    <div className="landing-bg_para">
                      <p>
                        (a) the User is in a breach of these Terms of Use or any
                        LINKSTECH SERVICES PRIVATE LIMITED Policies;
                      </p>
                      <p>
                        (b) the User is engaged or is suspected to engage in any
                        restricted, illegal, fraudulent or abusive activity;
                      </p>
                      <p>
                        (b) the User provides any information which is
                        incomplete, inaccurate or false or LINKSTECH SERVICES
                        has reason to believe that such information is
                        incomplete, inaccurate or false.
                      </p>
                    </div>
                    <div className="landing-bg_para">
                      Any suspension/termination of the User’s access to
                      LINKSTECH SERVICES PRIVATE LIMITED and Services shall be
                      without prejudice to LINKSTECH ’s right to exercise any
                      other remedy available to it under the applicable law.
                    </div>
                  </div>
                </div>

                <div className="landing-bg_para">
                  <u>GOVERNING LAW .</u>
                  This Terms of Use shall be governed by and construed in
                  accordance with the laws of India, without regard to its
                  conflict of law provisions and the exclusive jurisdiction of
                  competent courts in New Delhi, India.
                </div>

                <div className="landing-bg_para">
                  <u>FORCE MAJEURE .</u>
                  LINKSTECH SERVICES shall not be liable for failure to perform
                  its obligations under these Terms of Use to the extent such
                  failure is due to causes beyond its reasonable control. In the
                  event of a force majeure, the Company if unable to perform
                  shall notify the User in writing of the events creating the
                  force majeure and the performance obligations of the Company
                  will be extended by a period of time equal to the length of
                  the delay caused by force majeure; provided that if any such
                  delay exceeds ninety days, then following such ninety day
                  period, either Party hereto may terminate the unperformed
                  portions of these Terms of Use on ten days prior written
                  notice to the other Party. For the purposes of these Terms of
                  Use, force majeure events shall include, but not be limited
                  to, acts of God, failures or disruptions, orders or
                  restrictions, war or warlike conditions, hostilities,
                  sanctions, mobilizations, blockades, embargoes, detentions,
                  revolutions, riots, looting, strikes, stoppages of labour,
                  lockouts or other labour troubles, earthquakes, fires or
                  accidents and epidemics and/or pandemics.
                </div>

                <div className="landing-bg_para">
                  <u>MISCELLANEOUS .</u>
                  <div className="custom-list points-alpha mt-3">
                    <div className="landing-bg_para">
                      <u>SEVERABILITY</u>. If any provision of this Terms of Use
                      is held to be unlawful, void, invalid or otherwise
                      unenforceable, then that provision will be limited or
                      eliminated from this Terms of Use to the minimum extent
                      required, and the remaining provisions will remain valid
                      and enforceable.
                    </div>

                    <div className="landing-bg_para">
                      <u>ASSIGNMENT</u>. These Terms of Use and any rights
                      granted hereunder, shall not be assignable by the User,
                      but may be assigned by LINKSTECH SERVICES without
                      restriction. Any assignment attempted to be made in
                      violation of this provision shall be void and of no
                      effect.
                    </div>

                    <div className="landing-bg_para">
                      <u>HEADINGS</u>. The heading references herein are for
                      convenience purposes only, do not constitute a part of
                      these Terms of Use, and will not be deemed to limit or
                      affect any of the provisions hereof.
                    </div>

                    <div className="landing-bg_para">
                      <u>NOTICE</u>. LINKSTECH SERVICES may provide Users with
                      notices and communications by email, regular mail, or
                      posts on the LINKSTECH SERVICES website(s) or by any other
                      reasonable means. Except as otherwise set forth herein,
                      notice to LINKSTECH must be sent by courier or registered
                      post to the Legal Department, LINKSTECH SERVICES PRIVATE
                      LIMITED s Pvt. Ltd., 1st Floor, B1/A5, Mohan Cooperative
                      Industrial Estate, New Delhi - 110044, India.
                    </div>

                    <div className="landing-bg_para">
                      <u>WAIVER</u>. The failure of LINKSTECH SERVICES to
                      exercise or enforce any right or provision of these Terms
                      of Use will not constitute a waiver of such right or
                      provision. Any waiver of any provision of these Terms of
                      Use will be effective only if in writing and signed by
                      LINKSTECH .
                    </div>

                    <div className="landing-bg_para">
                      <u>RELATIONSHIP BETWEEN THE PARTIES</u>. The Terms of Use
                      are not intended by the Parties to constitute or create a
                      joint venture, pooling arrangement, partnership, agency or
                      formal business organization of any kind. LINKSTECH
                      SERVICES and the User shall be independent contractors
                      with each other for all purposes at all times and neither
                      Party shall act as or hold itself out as an agent or
                      representative of the other Party nor shall create or
                      attempt to create liabilities for the other Party by
                      acting or holding itself out as such.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Terms;
